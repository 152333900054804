import React from "react"
import Layout from "../../../components/sermonLayoutSurprise"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/0eKNPxbRZFU">
    <SEO title="Spiritual Supplements - Touching Holiness" />
  </Layout>
)

export default SermonPost
